@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
body {
  font-family: 'Roboto', sans-serif;
}


.Rutas__component {
  margin: 50px 200px;
}

.Rutas__Icono {
  width: 36px;
  height: auto;
}

.Rutas__resultados {
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.Layout__logo {
  text-align: center;
  margin-top: 100px;
}
