.Rutas__component {
  margin: 50px 200px;
}

.Rutas__Icono {
  width: 36px;
  height: auto;
}

.Rutas__resultados {
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
}